import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import usePlaywire from '../../contexts/usePlaywire';
import styles from './PlaywireAd.module.scss';
import cx from 'classnames';
import { PlaywireAdType } from '@resources/js/typings/viewModels/playwireAdViewModel';

export type PlaywireAdProps = {
  id: string;
  type: PlaywireAdType;
  maxWidth?: string;
  maxHeight?: string;
};

function PlaywireAd({
  id,
  type,
  maxHeight,
  maxWidth,
}: PlaywireAdProps): JSX.Element | null {
  const router = useRouter();
  const { isAvailable, isLoading, destroy, display } = usePlaywire();
  const [refreshCounter, setRefreshCounter] = useState(0);

  // Disabled to start, will be shown on first load, and routeChangeComplete event
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsHidden(containerRef.current?.offsetParent === null);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  useEffect(() => {
    setIsDisabled(false);
    setIsHidden(containerRef.current?.offsetParent === null);
  }, []);

  useEffect(() => {
    const onRouteChangeComplete = () => {
      setIsDisabled(false);
      setIsHidden(containerRef.current?.offsetParent === null);
      setRefreshCounter((generation) => generation + 1);
    };

    router?.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router?.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);

  useEffect(() => {
    if (!isAvailable || isDisabled || isHidden) return;

    // Ignore selectorIds for these special ad units
    const selectorId = ['bottom_rail', 'corner_ad_video'].includes(id)
      ? undefined
      : id;

    display({ selectorId, type }).catch(console.error);

    return () => {
      destroy({ selectorId, type }).catch(console.error);
    };
  }, [
    display,
    destroy,
    id,
    type,
    isAvailable,
    isHidden,
    refreshCounter,
    isDisabled,
  ]);

  const fallback = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',
        padding: '0 12px',
        backgroundColor: 'black',
        border: '1px solid white',
        width: '100%',
        height: '100%',
      }}
    >
      <h1>👋&nbsp;Hi there!</h1>
      <p>We want Archon to remain free. Help us by disabling your Adblock.</p>
      <p>
        Already support us on one of our other sites? Thank you! 🎉 <br />
        In the very near future your subscription on our other sites will be
        valid here as well!
      </p>
    </div>
  );

  return (
    <div
      className={cx({
        [styles.container]: true,
        [styles[type]]: true,
      })}
      ref={containerRef}
    >
      <div
        id={id}
        data-pw-desk={type}
        className={styles[type]}
        style={{ maxHeight, maxWidth }}
      >
        {!isLoading && !isAvailable && fallback}
      </div>
    </div>
  );
}

export default PlaywireAd;
