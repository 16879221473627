import React, { useEffect, useState } from 'react';
import VerticalContent from '@resources/js/components/VerticalContent/VerticalContent';
import cx from 'classnames';
import Button from '@resources/js/components/Button/Button';
import { useRouter } from 'next/router';
import ScreenReaderOnly from '@resources/js/components/ScreenReaderOnly/ScreenReaderOnly';
import styles from './Advertisements.module.scss';

export type AdvertisementsProps = {
  className?: string;
  direction: 'horizontal' | 'vertical';
  rounded?: boolean;
  showChrome?: boolean;
  children?: React.ReactNode;
};

export default function Advertisements({
  className = '',
  direction = 'vertical',
  rounded = true,
  showChrome = true,
  children,
}: AdvertisementsProps): JSX.Element | null {
  const router = useRouter();
  const [hideAd, setHideAd] = useState(false);

  useEffect(() => {
    setHideAd(false);
  }, [router.asPath]);

  if (hideAd) {
    return null;
  }

  return (
    <div
      className={cx({
        [styles.advertisements]: true,
        [styles.advertisementsChrome]: showChrome,
        [styles.advertisementsRounded]: rounded,
        [className || '']: Boolean(className),
      })}
    >
      <VerticalContent
        gap={'tiny'}
        className={cx({
          [styles.advertisementsRightAligned]:
            !showChrome && direction === 'horizontal',
        })}
      >
        <div
          className={cx({
            [styles.header]: true,
          })}
        >
          {showChrome ? (
            <>
              <div>Advertisements</div>
              {/*<a href='/remove-ads'>Remove Ads</a>*/}
            </>
          ) : (
            <Button styles={['unstyled']} onClick={() => setHideAd(true)}>
              <ScreenReaderOnly>Close Ad</ScreenReaderOnly>
              <CloseButtonIcon />
            </Button>
          )}
        </div>
        <div
          className={cx({
            [styles.spots]: true,
            [styles.spotsHorizontal]: direction === 'horizontal',
          })}
        >
          {children}
        </div>
      </VerticalContent>
    </div>
  );
}

function CloseButtonIcon(): JSX.Element {
  return (
    <>
      <svg
        width='12'
        height='12'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6 6L26 26'
          stroke='#DEDEDE'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M26 6L6 26'
          stroke='#DEDEDE'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </svg>
    </>
  );
}
